import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styles from './layout.module.css'
import logo from '../images/fp_logo.png'
import Row from './row'
import './global.css'

export default function Layout({ page, bg, children }) {
  const [scrolled, setScrolled] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, []);

  const onScroll = () => {
    setScrolled(document.documentElement.scrollTop >= 44)
  }

  const pages = [
    { key: 'home', to: '/', label: 'Home' },
    { key: 'about', to: '/about', label: 'About' },
    { key: 'investmet', to: '/investment-strategy', label: 'Investment Strategy' },
    { key: 'contact', to: '/contact', label: 'Contact' }
  ]

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${bg})` }}>
      <div className={`${styles.header} ${scrolled ? styles.scrolled : undefined}`}>
        <Row>
          <Link to='/' className={styles.logo}><img src={logo} alt="Logo" /></Link>
          <input className={styles.menuButton} type="checkbox" id="menu-btn" />
          <label className={styles.menuIcon} htmlFor="menu-btn"><span></span></label>
          <div className={styles.bg}></div>
          <ul className={styles.menu}>
            {pages.map(p => (
              <li key={p.key}><Link className={page === p.key ? styles.selected : undefined} to={p.to}>{p.label}</Link></li>
            ))}
          </ul>
        </Row>
      </div>
      <Row>
        {children}
      </Row>
    </div>
  )
}